<template>
  <div
    class="page"
    id="top"
  >
    <goods-grids :list="goodsList"></goods-grids>
    <div class="mv-15">
      <van-pagination
        :items-per-page="size"
        :total-items="total"
        @change="getList"
        mode="simple"
        v-model="page"
      />
    </div>
  </div>
</template>

<script>
import goodsGrids from "../components/goodsGrid.vue";
import Vue from "vue";
import { Pagination } from "vant";
Vue.use(Pagination);

export default {
  name: "history",
  components: { goodsGrids },
  data() {
    return {
      goodsList: [],
      size: 16,
      page: 1,
      total: 0,
    };
  },
  methods: {
    getList() {
      if (this.isloading) return;
      const param = {
        page: this.page,
        size: this.size,
        loading: true,
      };
      this.$http.get("user/his", param).then((res) => {
        const { data, total } = res;
        this.goodsList = data;
        this.total = total;
        document.getElementById("top").scrollIntoView({ behavior: "smooth" });
      });
    },
  },
  activated() {
    this.getList();
  },
};
</script>

<style>
</style>